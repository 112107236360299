<template>
    <div class="Form">
        <div class="FormRow">
            <div class="FormBoxWide FormTextArea">
                <div class="Field"><textarea v-model="this.messaggio" :placeholder="this.risorse['ScriviQui']"></textarea></div>
            </div>
        </div>
        <div class="FormRow">
            <div class="FormBoxWide FormCheckBox" style="margin-bottom:10px;">
                <div class="Label" v-html="this.risorse['PrivacyText']"></div>
                <div class="Field"><input type="checkbox" v-model="this.privacyAccepted" id=""  /><label for="" class="checkmark"></label></div>                
            </div>
            <div class="ErrorPrivacy" v-show="this.showPrivacyError">{{ this.risorse["CondizioniObbligatorie"] }}</div>
        </div>
        <div class="FormRow">
            <div class="FormBoxWide FormInputButton">
                <div class="Field Button"><input type="button"  :value="this.risorse['Invia']" @click="this.inviaEmail()" /></div>
            </div>
        </div>
    </div>
    <div class="Form">
        <div class="WhiteSpace">&nbsp;</div>
        <div class="loader" v-show="this.showLoader"></div> 
        <div class="Alert Good" v-show="this.showSuccess">
            <div class="AlertTitle">{{ this.risorse["MessOk"] }}</div>
            <div class="AlertText">{{ this.risorse["MessOkText"] }}</div>
        </div>

        <div class="Alert Bad" v-show="this.showError">
            <div class="AlertTitle">{{ this.risorse["MessKO"] }}</div>
            <div class="AlertText">{{ this.risorse["MessKoText"] }}</div>
        </div>
    </div>
</template>

<script>

import AnagraficaService from "@/services/anagrafica.service.js";
import {risorseMixin} from "@/mixin.js";

export default {
    name: "ModificaDatiForm",
    data(){
        return {
            urlCondizioni: process.env.VUE_APP_URL_CONDIZIONI,
            urlPrivacy: process.env.VUE_APP_URL_PRIVACY,
            showError: false,
            showSuccess: false,
            messaggio: "",
            showLoader: false,
            privacyAccepted: false,
            showPrivacyError: false           
        }        
    },
    watch: {
        privacyAccepted: function(newVal){
            if(newVal){
                this.showPrivacyError = false;
            }
        }
    },
    mixins:[risorseMixin],
    methods:{
        resetData(){
            this.showError = false;
            this.showSuccess = false;
            this.messaggio = "";
            this.showPrivacyError = false;
        },

        inviaEmail(){

            if(!this.privacyAccepted){
                this.showPrivacyError = true;
                return;
            }

            this.showLoader = true;
            AnagraficaService. modificaDati(this.$store.getters.getToken, this.messaggio).then(
                (data) => {
                    this.showLoader = false;

                    if(data.status == 200){
                        this.showSuccess = true;
                        this.messaggio = "";    
                    }
                    else{
                        this.showError = true;      
                    }
                }
            );
        }
    }
}
</script>
<style>
.ErrorPrivacy{
    color: #ff0000;
    margin-bottom: 20px;
}
</style>