<template>
    <div class="ProfileBox">
        <h2>{{ this.risorse["ModdatiPers"] }}</h2>    
        <span v-html="this.risorse['CambioDatiTextProfilo']"></span>
        <ModificaDatiForm/>
    </div>
</template>

<script>

import ModificaDatiForm from "@/components/ModificaDatiForm.vue";
import {risorseMixin} from "@/mixin.js";

export default {
    name: "ModificaDatiAnagrafici",
    components: {
        ModificaDatiForm
    },
    mixins:[risorseMixin]
}    
</script>