import axios from 'axios'
const API_URL = process.env.VUE_APP_API_URL;

class AnagraficaService{

  async modificaDati(token, messaggio){
      return axios({
        url: API_URL + 'anagrafica/ModificaDati',
        method: "post",          
        headers:{
          Authorization: 'Bearer '+ token,
          'Content-Type': 'application/json'
        },     
        data: JSON.stringify(messaggio)
      })
      .then(
        data =>  { 
          return data;
        }
      )
      .catch(error => {       
        return error.response;
      });
    }

    async getEmailUtente(token){
      return axios({
        url: API_URL + 'anagrafica/GetUserEmail',
        method: "get",          
        headers:{
          Authorization: 'Bearer '+ token,
          'Content-Type': 'application/json'
        }
      })
      .then(
          data =>  {               
              if(data.status == 401){
                  return null;
              }
              else{
                  return data.data;
              }               
          }
      )
      .catch(error => {return error;});   
    }
}
export default new AnagraficaService();